import React, { useState, useRef, useEffect } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Icon } from '@hurtigruten/design-system-components';
import clsx from 'clsx';

import {
  useLocale,
  useMediaQuery,
  useTranslate,
  usePrevious,
  useQuoteMetaInformationList,
  useUrlBuilder,
  useAppContext,
  useElementSize
} from '@hooks';
import { useSiteData } from '@context/DataContext';
import { landing, appbar, supportHub } from '@microcopies';
import { breakpoints, isFeatureEnabled } from '@src/utils';
import { post } from '@src/utils/http';
import BookingInProgressPreviewList from '@components/organisms/BookingInProgressPreviewList';
import { TQuoteMetaInformation } from '@src/types/quoteMetaInformation';
import FocusLock from '@components/FocusLock';
import { ContactIcon } from '@components/shared/icons';

import HeaderRibbon from './HeaderRibbon';
import NavButton from './NavButton';
import MegaNavPanelDesktop from './MegaNavPanelDesktop';
import MegaNavPanelMobile from './MegaNavPanelMobile';
import MyAccountDropdown from './MyAccountDropdown';
import CountrySelectorDesktop from './CountrySelectorDesktop';
import Hamburger from './Hamburger';
import LogoExpanded from './HxLogoExpanded';
import LogoCompact from './HxLogoCompact';
import HXLogoXL from './HXLogoXL';
import HXLogoL from './HXLogoL';
import HXLogoSM from './HXLogoSM';
import { SectionNames } from './constants';

const hoverClass = () =>
  clsx(
    'inline bg-gradient-to-r from-black to-black bg-leftOffBottom bg-no-repeat bg-[length:0%_1px] w-fit duration-200 hover:bg-[length:100%_1px] transition-bgsize'
  );

const BookingHeaderBackLink = ({
  contextualText,
  contextualBackHref,
  hoverClasses,
  backToText
}: {
  contextualText?: string;
  contextualBackHref?: string;
  hoverClasses: string;
  backToText: string;
}) => (
  <div className="flex items-center flex-1 mr-auto">
    {contextualBackHref && (
      <NextLink
        href={contextualBackHref}
        passHref
        className={`flex items-center ${hoverClasses}`}
      >
        <Icon graphic="arrow-left" />
        {contextualText && (
          <span className="hidden ml-2 text-sm text-black tabletLandscape:block">
            {backToText} {contextualText}
          </span>
        )}
      </NextLink>
    )}
  </div>
);

export const attachRef =
  (ref: React.MutableRefObject<any[]>, index: number) =>
  (element: HTMLButtonElement) => {
    ref.current[index] = element;
  };

const MegaNav = ({
  bookingHeader,
  contextualText,
  contextualBackHref,
  fixedHeader
}: {
  bookingHeader?: boolean;
  contextualText?: string;
  contextualBackHref?: string;
  fixedHeader?: boolean;
}) => {
  const { data: siteData } = useSiteData();
  const { state: appData } = useAppContext();
  const { megaNav, headerRibbon } = siteData;
  const { showStickyBar } = appData;
  const locale = useLocale();
  const router = useRouter();
  const { route } = router;
  const { buildBookingStepUrl } = useUrlBuilder();
  const isDesktop = useMediaQuery(breakpoints.tabletLandscape);
  const isSmallDesktop = useMediaQuery(breakpoints.laptop, true);
  const isMobile = useMediaQuery(breakpoints.mobileLandscape, true);
  const megaNavRef = useRef<HTMLDivElement>(null);
  const [loadingPreviewId, setLoadingPreviewId] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [activeTabIndex, setActiveTabIndex] = useState<number | null>(null);
  const [menuAction, setMenuAction] = useState<'open' | null>(null);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const mainMenuRefsDesktop = useRef<HTMLButtonElement[]>([]);
  const [usedMouseClick, setUsedMouseClick] = useState(false);
  const headerContainerRef = useRef<HTMLDivElement>(null);
  const { height: headerHeight } = useElementSize(headerContainerRef);
  const previousTab = usePrevious(activeTab);
  const mainMenuSections =
    megaNav?.sections.filter((s) => s.navControl !== SectionNames.ContactUs) ??
    [];
  const contactUsSecton =
    megaNav?.sections.filter((s) => s.navControl === SectionNames.ContactUs) ??
    [];
  const [navOriginalPosition, setNavOriginalPosition] = useState<boolean>(true);
  const [navVisible, setNavVisible] = useState<boolean>(true);

  // Handle style change on new destination page
  const [isNewDestinationPage, setIsNewDestinationPage] = useState(
    router.pathname === '/destinations/[destination]'
  );
  const showRibbon = isFeatureEnabled('showHrgGroupBanner') && headerRibbon;

  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      const moving = window.scrollY;
      const navHeight = isDesktop ? 152 : 104;

      if (moving <= navHeight) {
        setNavVisible(true);
        setNavOriginalPosition(true);
      } else if (moving > lastScrollTop && moving > navHeight) {
        setNavVisible(false);
      } else {
        /* Prevent scrolling 
        up re-showing the main nav as it disrupts page flow */
        if (!isNewDestinationPage) {
          setNavVisible(true);
        }
        setNavOriginalPosition(false);
      }

      lastScrollTop = moving;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isNewDestinationPage]);

  const translateLanding = useTranslate(landing, (x) => x.landing);
  const translateAppbar = useTranslate(appbar, (x) => x.appbar);
  const translateSupport = useTranslate(supportHub, (x) => x.supportHub);

  useEffect(() => {
    setIsNewDestinationPage(router.pathname === '/destinations/[destination]');
  }, [router.pathname]);

  // START HANDLE MENU FUNCTIONS
  const handleMenuStates = (clickedTab: string | null) => {
    if (activeTab === clickedTab || clickedTab === null) {
      setActiveTab(null);
      setMenuAction(null);
      setActiveTabIndex(null);
    } else {
      setMenuAction('open');

      setActiveTab(clickedTab);

      /* Determine which index of tabs we have clicked so that we
      can disable tabbing to non-active tabs (so that you can then
        tab to the content). NOTE: Because homebutton is in the
        middle, we need to add 1 to the index for tabs after it. */
      let index = Object.values(SectionNames).indexOf(clickedTab);
      switch (clickedTab) {
        case SectionNames.ContactUs:
        case SectionNames.CountrySelect:
        case SectionNames.Account:
          index += 1;
          break;
        default:
      }
      setActiveTabIndex(index);
    }
  };

  const onClickOutside = (event: MouseEvent) => {
    if (
      megaNavRef.current &&
      !megaNavRef.current.contains(event.target as Node)
    ) {
      setActiveTab(null);
      setMenuAction(null);
      setActiveTabIndex(null);
      setUsedMouseClick(false);
    }
  };

  const showBgOverlay = () => {
    if (
      activeTab !== null &&
      activeTab !== SectionNames.Account &&
      activeTab !== SectionNames.CountrySelect &&
      isDesktop
    ) {
      return true;
    }
    return false;
  };

  // close on escape
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        // what is the activeTab and did we get to it by keyboard?
        if (!usedMouseClick && activeTabIndex !== null && isDesktop) {
          // if so, return focus back to the main menu item
          mainMenuRefsDesktop.current[activeTabIndex].focus();
        }
        setActiveTab(null);
        setMenuAction(null);
        setMobileOpen(false);
        setActiveTabIndex(null);
        setUsedMouseClick(false);
      }
    };
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [activeTabIndex, mainMenuRefsDesktop, usedMouseClick]);

  useEffect(() => {
    document.addEventListener('click', onClickOutside);

    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, []);

  const toggleOpenMobile = (value: boolean, target?: string) => {
    if (
      activeTab !== SectionNames.ContactUs &&
      target === SectionNames.ContactUs
    ) {
      setMobileOpen(true);
    } else {
      setMobileOpen(value);
      if (value) {
        setActiveTab(null);
        setActiveTabIndex(0);
      }
      if (!value) {
        setActiveTabIndex(null);
      }
    }
  };

  useEffect(() => {
    if (mobileOpen === true) {
      setMenuAction('open');
    } else {
      if (activeTab !== SectionNames.Account) {
        setActiveTab(null);
      }
      setMenuAction(null);
    }
  }, [mobileOpen]);
  // END HANDLE MENU FUNCTIONS

  // START UNFINISHED BOOKING FUNCTIONS
  const [hasLastStartedBookingDeeplink, setHasLastStartedBookingDeeplink] =
    useState(false);

  useEffect(() => {
    setHasLastStartedBookingDeeplink(
      Boolean(window.localStorage.getItem('lastStartedBookingDeeplink'))
    );
  }, []);

  const {
    list: quoteMetaInformationList,
    set: setQuoteMetaInformationList,
    closePreview
  } = useQuoteMetaInformationList();

  const isBookingPage = route.includes('/booking/');

  const showUnfinishedBookings =
    (isFeatureEnabled('showContinueBookingButton') &&
      quoteMetaInformationList &&
      quoteMetaInformationList.length > 0 &&
      !isBookingPage) ||
    (isFeatureEnabled('showDeeplink') &&
      hasLastStartedBookingDeeplink &&
      !isBookingPage);

  const onPreviewClick = async ({
    step,
    voyageSlug,
    quoteId,
    voyageId,
    cabinIndex
  }: TQuoteMetaInformation) => {
    try {
      // Extend quote in seaware
      const { holdExpires } = await post<{ holdExpires: number }>({ quoteId })(
        '/api/booking-domain/quote/extend/'
      );
      // Update state and local storage with new expiration timestamp
      setQuoteMetaInformationList((list) => {
        const item = list?.find((x) => x.quoteId === quoteId);
        if (item) {
          item.holdExpires = holdExpires;
        }
        return list ?? [];
      });
    } catch (error) {
      console.error(`Unable to extend quote "${quoteId}"`, error);
    }

    void router.push(
      buildBookingStepUrl(step, quoteId, voyageId, voyageSlug, cabinIndex)
    );
    setLoadingPreviewId(null);
  };
  const getLogoLocale = React.useCallback((localeStr: string) => {
    const getHXLogo = () => {
      if (isDesktop) return <HXLogoXL />;
      if (isSmallDesktop && !isMobile) return <HXLogoL />;
      return <HXLogoSM />;
    };
    switch (localeStr) {
      case 'en-us':
       return getHXLogo()
      case 'en-au':
        return getHXLogo()
      default:
        return isDesktop && isSmallDesktop ? <LogoCompact /> : <LogoExpanded />
    }
  }, [isDesktop, isSmallDesktop, isMobile]);

  // END UNFINISHED BOOKING FUNCTIONS
  return (
    <header
      ref={headerContainerRef}
      className={clsx('left-0 w-full z-100  transition-all', {
        'fixed ': fixedHeader,
        'top-0':
          (navVisible && navOriginalPosition) ||
          (navVisible && !navOriginalPosition && !showRibbon),
        '-top-[40px]': navVisible && !navOriginalPosition && showRibbon,
        '-top-[152px]': !navVisible
      })}
    >
      {showRibbon && (
        <HeaderRibbon
          text={headerRibbon.text}
          link={headerRibbon?.link}
          logo={headerRibbon.logo}
          isNewDestinationPage={isNewDestinationPage}
        />
      )}
      <FocusLock
        isLocked={activeTab !== null || (mobileOpen === true && !isDesktop)}
        riseToTop={false}
        defaultFocusIndex={-2}
      >
        <div
          className={clsx(
            'z-40 opacity-0 bg-white fixed top-0 left-0 right-0 transition-all',
            {
              ' opacity-50 bottom-0': showBgOverlay()
            }
          )}
        ></div>
        {megaNav && (
          <div ref={megaNavRef}>
            <nav
              aria-label="Main Navigation"
              data-testid="MegaNav"
              className={clsx(
                'flex bg-white justify-center h-[64px] tablet:h-[80px] tabletLandscape:h-[112px] px-4 tablet:px-6 laptop:px-9 relative z-[101]  transition ease-in-out-smooth ',
                'after:border-b after:border-hx-neutrals-10 after:bottom-0 after:left-0 after:right-0 after:absolute',
                {
                  '-top-[64px] tablet:-top-[80px] tabletLandscape:-top-[112px] ':
                    showStickyBar
                }
              )}
            >
              {!bookingHeader ? (
                <>
                  {isDesktop ? (
                    <div className="flex items-center flex-1 mr-auto">
                      {mainMenuSections?.map((s, i) => (
                        <NavButton
                          key={i}
                          className="m-3"
                          active={activeTab === s.navControl}
                          open={menuAction !== null}
                          onClick={() => handleMenuStates(s.navControl)}
                          aria-expanded={activeTab === s.navControl}
                          aria-controls={`nav-${s.navControl
                            .replace(/[^A-Za-z0-9]/g, '')
                            .toLowerCase()}`}
                          ref={attachRef(mainMenuRefsDesktop, i)}
                          onMouseDown={() => setUsedMouseClick(true)}
                          tabIndex={
                            activeTabIndex !== null && activeTabIndex !== i
                              ? -1
                              : 0
                          }
                        >
                          <div className="text-sm font-normal">
                            {s.sectionName}
                          </div>
                        </NavButton>
                      ))}
                    </div>
                  ) : (
                    <div className="w-[24px]  px-1 py-4  mr-auto flex-1 flex items-center">
                      <Hamburger
                        toggleMenu={toggleOpenMobile}
                        active={mobileOpen}
                      />
                    </div>
                  )}
                </>
              ) : (
                <BookingHeaderBackLink
                  contextualText={contextualText}
                  contextualBackHref={contextualBackHref}
                  hoverClasses={hoverClass()}
                  backToText={translateAppbar((x) => x.backTo)}
                />
              )}

              <div className="flex items-center justify-center ">
                <NextLink
                  locale={locale}
                  href={'/'}
                  aria-label={translateLanding((x) => x.mainTitle)}
                  className={clsx({
                    'w-auto': ['en-us', 'en-au'].includes(locale),
                    'tabletLandscape:w-[34px] w-[93px] laptop:w-[156px]': !['en-us', 'en-au'].includes(locale)
                  })}
                  onClick={() => {
                    handleMenuStates(null);
                    setMobileOpen(false);
                  }}
                  tabIndex={
                    activeTabIndex !== null &&
                    activeTabIndex !== mainMenuSections.length
                      ? -1
                      : 0
                  }
                >
                  {getLogoLocale(locale)}
                </NextLink>
              </div>
              <div className="flex items-center justify-end flex-1 ml-auto ">
                {isDesktop && !bookingHeader && (
                  <CountrySelectorDesktop
                    activeTab={activeTab === SectionNames.CountrySelect}
                    menuAction={menuAction !== null}
                    setCountrySelectorActive={() =>
                      handleMenuStates(SectionNames.CountrySelect)
                    }
                    parentRef={attachRef(
                      mainMenuRefsDesktop,
                      mainMenuSections.length + 1
                    )}
                    tabIndex={
                      activeTabIndex !== null &&
                      activeTabIndex !== mainMenuSections.length + 1
                        ? -1
                        : 0
                    }
                  />
                )}
                {isDesktop && (
                  <div
                    className={clsx(
                      'mx-1 tablet:mx-2  laptop:mx-[10px] mt-1 text-sm font-normal',
                      {
                        'text-hx-neutrals-70': menuAction !== null
                      }
                    )}
                  >
                    {translateAppbar((x) => x.phoneNumber)}
                  </div>
                )}
                <NavButton
                  onClick={() => {
                    handleMenuStates(SectionNames.ContactUs);
                    toggleOpenMobile(!mobileOpen, SectionNames.ContactUs);
                  }}
                  active={activeTab === SectionNames.ContactUs}
                  aria-label={translateSupport((x) => x.contactUs)}
                  open={menuAction !== null}
                  ref={attachRef(
                    mainMenuRefsDesktop,
                    mainMenuSections.length + 2
                  )}
                  tabIndex={
                    activeTabIndex !== null &&
                    activeTabIndex !== mainMenuSections.length + 2
                      ? -1
                      : 0
                  }
                >
                  <span className="text-sm font-normal ">
                    {isDesktop ? (
                      contactUsSecton[0].sectionName
                    ) : (
                      <ContactIcon />
                    )}
                  </span>
                </NavButton>
                {!bookingHeader && (
                  <MyAccountDropdown
                    activeTab={activeTab === SectionNames.Account}
                    menuAction={menuAction !== null}
                    setAccountActive={() =>
                      handleMenuStates(SectionNames.Account)
                    }
                    parentRef={attachRef(
                      mainMenuRefsDesktop,
                      mainMenuSections.length + 3
                    )}
                    closeMobileMenu={() => setMobileOpen(false)}
                    tabIndex={
                      activeTabIndex !== null &&
                      activeTabIndex !== mainMenuSections.length + 3
                        ? -1
                        : 0
                    }
                  />
                )}
              </div>
            </nav>

            {isDesktop ? (
              <MegaNavPanelDesktop
                sections={megaNav.sections}
                onClick={handleMenuStates}
                activeTab={activeTab}
                headerHeight={headerHeight}
              />
            ) : (
              <MegaNavPanelMobile
                sections={megaNav.sections}
                onClick={handleMenuStates}
                toggleMenu={toggleOpenMobile}
                activeTab={activeTab}
                previousTab={previousTab}
                open={mobileOpen}
                ribbonLink={headerRibbon?.link}
                navOriginalPosition={navOriginalPosition}
              />
            )}
          </div>
        )}
        {showUnfinishedBookings && (
          <BookingInProgressPreviewList
            items={quoteMetaInformationList ?? []}
            loadingId={loadingPreviewId}
            onItemClick={(item) => onPreviewClick(item)}
            closePreview={closePreview}
          />
        )}
      </FocusLock>
    </header>
  );
};

export default MegaNav;
