import { useEffect, useMemo } from 'react';

type Config = {
  orgId: string;
  deploymentId: string;
  targetURL: string;
  scrt2URL: string;
  scriptSrc: string;
};
const chatBotConfig = {
  staging: {
    orgId: '00DUB000003rRV3',
    deploymentId: 'Staging_TravelHX',
    targetURL:
      'https://travelhx--hxafpilot.sandbox.my.site.com/ESWStagingTravelHX1734602554229',
    scrt2URL: 'https://travelhx--hxafpilot.sandbox.my.salesforce-scrt.com',
    scriptSrc:
      'https://travelhx--hxafpilot.sandbox.my.site.com/ESWStagingTravelHX1734602554229/assets/js/bootstrap.min.js'
  },
  production: {
    orgId: '00D06000001TGAp',
    deploymentId: 'TravelHX_Agentforce',
    targetURL:
      'https://travelhx.my.site.com/ESWTravelHXAgentforce1737561501427',
    scrt2URL: 'https://travelhx.my.salesforce-scrt.com',
    scriptSrc:
      'https://travelhx.my.site.com/ESWTravelHXAgentforce1737561501427/assets/js/bootstrap.min.js'
  }
}; // in future wrap it up to .env!
const initChatbot = (config: Config) => {
  const { orgId, deploymentId, targetURL, scrt2URL } = config;
  try {
    if (typeof window.embeddedservice_bootstrap !== 'undefined') {
      window.embeddedservice_bootstrap.settings.language = 'en_US';
      window.embeddedservice_bootstrap.settings.enableUserInputForConversationWithBot =
        false;
      window.embeddedservice_bootstrap.init(orgId, deploymentId, targetURL, {
        scrt2URL
      });
    }
  } catch (error) {
    console.error('Chatbot initialization failed!', error);
  }
};
const chatbotDestinations: Record<string, string[]> = {
  antarctica: ['antarctica-cruises'],
  galapagos: ['galapagos-islands-cruises']
};
export const shouldShowChatForDestination = (slug: string): boolean =>
  Object.values(chatbotDestinations).some((slugs) => slugs.includes(slug));
export const useChatbotScript = (
  showChat: boolean,
  isChatEnabled?: boolean,
  locale?: TLocale,
  isStaging?: boolean
) => {
  const config = useMemo(
    () => (isStaging ? chatBotConfig.staging : chatBotConfig.production),
    [isStaging]
  );
  useEffect(() => {
    if (!isChatEnabled || locale !== 'en-gb') {
      return () => {};
    }
    const { scriptSrc } = config;
    let scriptElement: HTMLScriptElement | null;
    const addScript = () => {
      const existingScript = document.querySelector(
        `script[src="${scriptSrc}"]`
      );
      if (!existingScript) {
        scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.src = scriptSrc;
        scriptElement.onload = () => {
          try {
            initChatbot(config);
          } catch (error) {
            console.error('Chatbot initialization failed', error);
          }
        };
        document.body.appendChild(scriptElement);
      }
    };
    const removeScript = () => {
      const existingScript = document.querySelector(
        `script[src="${scriptSrc}"]`
      );
      if (existingScript) {
        try {
          existingScript.remove();
        } catch (error) {
          console.error('Failed to remove script', error);
          try {
            document.body.removeChild(existingScript);
          } catch (nestedError) {
            console.warn('Failed to remove script:', nestedError);
          }
        }
      }
    };
    if (showChat) {
      addScript();
    } else {
      removeScript();
    }
    return () => {
      removeScript();
    };
  }, [showChat, isChatEnabled, locale, isStaging]);
};
