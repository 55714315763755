import { gql } from '@apollo/client';

import { getContentApiClient } from '@content/apolloClient';
import { FooterInfo } from '@content/models/footerInfo';

import { RequestConfig } from '../types';

const query = gql`
  query B2cExpeditionFooter($locale: String!) {
    b2cExpeditionFooter(locale: $locale) {
      contactInfo {
        id
        phoneNumbers {
          id
          phoneNumber
          label
          availability
          countryCode
        }
        emails {
          id
          email
          label
        }
        externalLinks {
          id
          url
          label
          urlText
          salesforceId
        }
      }
      goToLinks {
        url
        label
      }
      aboutLinks {
        url
        label
      }
      supportLinks {
        label
        url
      }
      destinationLinks {
        label
        url
      }
      coastalLinks {
        label
        url
      }
      legalLinks {
        label
        url
      }
      socialMediaLinks {
        label
        url
        urlText
      }
      newsletterPromotion {
        cta {
          label
          link {
            ... on B2cExpeditionExternalLink {
              url
              label
            }
          }
          slug
        }
        promoBannerDescription
        descriptionPosition
        image {
          url
          alt
        }
        showSocialMediaLinks
        title
      }
    }
  }
`;

interface QueryData {
  b2cExpeditionFooter: FooterInfo;
}

export const getFooter = async ({ locale, preview }: RequestConfig) => {
  try {
    const res = await getContentApiClient(preview).query<QueryData>({
      query,
      variables: {
        locale
      }
    });
    return res.data.b2cExpeditionFooter;
  } catch {
    console.error('-----getFooter graphql api error-----');
  }
  return null;
};

export const ambassadorSysId = '70WoTzTT8wZscOVgvj8fwC';
