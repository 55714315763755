const  HXLogoL = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="48" viewBox="0 0 33 48" fill="none">
        <g>
            <g>
                <path d="M30.4414 38.3332L25.1287 20.8802L28.716 10.8358C29.1608 9.61611 29.9173 9.49601 31.1439 9.41227V8.92857H25.2652V9.41227C26.4467 9.3704 27.4002 9.7891 27.4002 11.0871C27.4002 11.2545 27.3892 11.4749 27.1282 12.3432L24.7609 19.6472L21.9796 9.41337H24.4736V8.92967H16.1483V0.828981H18.6456V0.345276H9.46923V0.828981H11.9676V22.9318H6.67801V9.41227H9.18185V8.92857H0V9.41227H2.49723V38.3332H0V38.8169H9.17414V38.3332H6.67691V23.6843H11.9665V47.171H9.46923V47.6547H18.6456V47.171H16.1483V38.8169H23.0554V38.3332C22.5929 38.3189 22.0358 38.2109 21.4885 38.0886C20.5482 37.8782 20.056 37.4815 19.8303 37.0132C19.707 36.7565 19.663 36.4777 19.6696 36.1968C19.6905 35.2999 20.3357 33.7441 20.3357 33.7441L22.812 26.894L26.2144 38.3332H23.7171V38.8169H32.9408V38.3332H30.4414ZM17.4179 37.6413C17.0843 37.9454 16.6758 38.1724 16.1483 38.2748V9.41227H17.5863L22.4454 25.5266L18.8405 34.9396C18.8405 34.9396 18.5674 35.7142 18.2657 36.383C18.116 36.7146 17.746 37.3427 17.4179 37.6413Z" fill="#202835"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_10493_14893">
                <rect width="32.9408" height="47.3095" fill="white" transform="translate(0 0.345276)"/>
            </clipPath>
            <clipPath id="clip1_10493_14893">
                <rect width="32.9408" height="47.3095" fill="white" transform="translate(0 0.345276)"/>
            </clipPath>
        </defs>
    </svg>
)
export default HXLogoL;