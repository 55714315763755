import React from 'react';

const HXLogoXL = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="70" viewBox="0 0 48 70" fill="none">
        <g>
            <path d="M44.3579 55.7229L36.6165 30.4893L41.8438 15.9672C42.492 14.2038 43.5942 14.0301 45.3816 13.909V13.2097H36.8155V13.909C38.537 13.8485 39.9265 14.4539 39.9265 16.3305C39.9265 16.5726 39.9104 16.8912 39.5302 18.1465L36.0806 28.7067L32.0278 13.9106H35.6619V13.2113H23.5307V1.49933H27.1696V0.799988H13.7982V1.49933H17.4386V33.4555H9.73092V13.909H13.3794V13.2097H0V13.909H3.63887V55.7229H0V56.4223H13.3682V55.7229H9.72932V34.5436H17.437V68.5007H13.7982V69.2H27.1696V68.5007H23.5307V56.4223H33.5953V55.7229C32.9215 55.7022 32.1096 55.5461 31.3122 55.3693C29.942 55.065 29.2249 54.4915 28.8959 53.8145C28.7162 53.4433 28.6521 53.0403 28.6617 52.6341C28.6922 51.3373 29.6324 49.088 29.6324 49.088L33.2408 39.1841L38.1985 55.7229H34.5596V56.4223H48V55.7229H44.3579ZM25.3806 54.7225C24.8945 55.1622 24.2992 55.4904 23.5307 55.6385V13.909H25.6261L32.7065 37.2071L27.4536 50.8164C27.4536 50.8164 27.0557 51.9363 26.616 52.9033C26.3978 53.3828 25.8587 54.2908 25.3806 54.7225Z" fill="#202835"/>
        </g>
        <defs>
            <clipPath id="clip0_10493_14889">
                <rect width="48" height="68.4" fill="white" transform="translate(0 0.799988)"/>
            </clipPath>
        </defs>
    </svg>
);
export default HXLogoXL;