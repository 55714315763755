import { getContentApiClient } from '@content/apolloClient';

import { getMegaNav, MegaNavData } from '../../queries/contentApi/getMegaNav';
import { RequestConfig } from '../../types';

export const getMegaNavQuery = async ({
  locale
}: RequestConfig): Promise<MegaNavData | null> => {
  try {
    const response = await getContentApiClient().query<
      Record<'megaNav', MegaNavData>
    >({
      query: getMegaNav,
      variables: {
        locale
      }
    });

    const pageData = response.data.megaNav;

    if (!pageData) {
      return null;
    }

    return pageData;
  } catch {
    console.error('-----getMegaNavQuery graphql api error-----');
  }
  return null
};
