const HXLogoSM = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="32" viewBox="0 0 22 32" fill="none">
        <g>
            <g>
                <path d="M20.2942 25.5555L16.7525 13.9201L19.144 7.22389C19.4405 6.41074 19.9448 6.33067 20.7626 6.27484V5.95237H16.8435V6.27484C17.6311 6.24693 18.2668 6.52606 18.2668 7.39137C18.2668 7.50302 18.2595 7.64993 18.0855 8.22876L16.5073 13.0981L14.6531 6.27558H16.3157V5.95311H10.7656V0.552649H12.4304V0.230179H6.31282V0.552649H7.97838V15.2878H4.45201V6.27484H6.12123V5.95237H0V6.27484H1.66482V25.5555H0V25.8779H6.1161V25.5555H4.45127V15.7896H7.97764V31.4474H6.31282V31.7698H12.4304V31.4474H10.7656V25.8779H15.3702V25.5555C15.0619 25.5459 14.6905 25.4739 14.3257 25.3924C13.6988 25.2521 13.3707 24.9877 13.2202 24.6755C13.138 24.5043 13.1086 24.3185 13.113 24.1312C13.127 23.5332 13.5571 22.496 13.5571 22.496L15.208 17.9293L17.4762 25.5555H15.8114V25.8779H21.9605V25.5555H20.2942ZM11.6119 25.0942C11.3895 25.2969 11.1172 25.4482 10.7656 25.5165V6.27484H11.7242L14.9636 17.0177L12.5603 23.293C12.5603 23.293 12.3783 23.8094 12.1771 24.2553C12.0773 24.4764 11.8307 24.8951 11.6119 25.0942Z" fill="#202835"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_10493_14917">
                <rect width="21.9605" height="31.5396" fill="white" transform="translate(0 0.230179)"/>
            </clipPath>
            <clipPath id="clip1_10493_14917">
                <rect width="21.9605" height="31.5396" fill="white" transform="translate(0 0.230179)"/>
            </clipPath>
        </defs>
    </svg>
)
export default HXLogoSM;